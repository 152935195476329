import Rollbar from 'rollbar'
import isbot from 'isbot'

const IGNORED_MESSAGES = [
  /The play\(\) request was interrupted by a call to pause\(\)/,
  /^Script error\.?$/,
  /^(?=.*unknown)(?=.*SyntaxError).*$/
]

Rollbar.init({
  accessToken: '98b7efec5222419da2879b864eb95942',
  enabled: (window.rollbarEnvironment === 'production' || window.rollbarEnvironment === 'staging'),
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: IGNORED_MESSAGES,
  payload: {
    environment: window.rollbarEnvironment,
    client: {
      javascript: {
        code_version: window.codeVersion
      }
    }
  },
  scrubFields: ['credit-card-number', 'expiration-month', 'expiration-year', 'cvv'],
  scrubTelemetryInputs: true,
  hostSafeList: [
    /envato-static/,
    /envato-staging/,
    /themeforest/,
    /codecanyon/,
    /videohive/,
    /audiojungle/,
    /3docean/,
    /photodune/,
    /graphicriver/
  ],
  checkIgnore: function (_isUncaught, _args, _payload) {
    if (window.navigator.userAgent) {
      const foundFF = window.navigator.userAgent.match(/ Firefox\/(\d+)/)
      if (foundFF) {
        // ignore Firefox version below 103 (released in 2022)
        return (+foundFF[1] < 103)
      }
      const foundCh = window.navigator.userAgent.match(/ Chrome\/(\d+)/)
      if (foundCh) {
        // ignore Chrome version below 103 (released in 2022)
        return (+foundCh[1] < 103)
      }
      const foundSaf = window.navigator.userAgent.match(/ Version\/(\d+).* Safari\//)
      if (foundSaf) {
        // ignore Safari version below 15
        return +foundSaf[1] < 15
      }
      if (isbot(window.navigator.userAgent)) {
        return true
      }
    }
    return false
  }
})
